





















































import {
  defineComponent,
  PropType
} from '@nuxtjs/composition-api'
import { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';
import { prepareCardProduct } from '@wemade-vsf/catalog/lib/runtime/product/helpers/prepareCardProduct';
import { useImage } from '@wemade-vsf/composables';
import ProductPrice from 'components/catalog/Product/ProductPrice.vue';

export default defineComponent({
  name: 'ProductCard',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: [Boolean, Array],
      default: false
    }
  },
  components: {
    ProductPrice,
    ProductCardActions: () => import('components/catalog/Product/ProductCard/Actions.vue'),
    ProductLabels: () => import('components/catalog/Product/ProductLabels.vue')
  },
  setup(props) {
    const { imageSizes: { productCard: imageSize } } = useImage();
    const cardProduct = prepareCardProduct(props.product)
    const { title, image, link, nuxtImgConfig, specialPrice, regularPrice, hasPriceRange, priceIncludesTax } = cardProduct.cardProps
    const { sku } = cardProduct
    const hasLabels = !!props.product.product_labels?.length;
    const availableActions = ['link', ...cardProduct.cardProps.showCartButton ? ['cart'] : []];

    return {
      cardProduct,
      image,
      imageSize,
      link,
      nuxtImgConfig,
      regularPrice,
      sku,
      specialPrice,
      title,
      hasPriceRange,
      hasLabels,
      availableActions,
      priceIncludesTax
    }
  }
})
