


























import {
  defineComponent,
  ref,
  onMounted,
  computed,
  useContext
} from '@nuxtjs/composition-api'
import { useProduct } from '@wemade-vsf/catalog/lib/runtime/product/composables/useProduct';
import ProductCard from 'components/catalog/Product/ProductCard.vue';
import { SfHeading, SfLink } from '@storefront-ui/vue'

export default defineComponent({
  name: 'ProductGrid',
  props: {
    title: {
      type: String,
      default: ''
    },
    skus: {
      type: String,
      default: ''
    },
    columns: {
      type: String,
      default: "4"
    },
    link: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    }
  },
  components: {
    ProductCard,
    SfHeading,
    SfLink
  },
  setup (props) {
    const { localePath } = useContext()
    const { getProductList } = useProduct()
    const skus = props.skus.split(',').map(s => s.trim())
    const products = ref([])
    const hasLink = computed(() => !!props.link && !!props.linkText)
    const isExternal = computed(() => hasLink && props.link.startsWith('http'))
    const ctaLink = computed(() => isExternal ? props.link : localePath(props.link))

    onMounted(async() => {
      if (skus.length === 0) return
      const baseSearchQuery = {
        filter: {
          sku: {
            in: skus
          }
        },
        pageSize: skus.length
      }
      const { items } = await getProductList(baseSearchQuery)
      products.value = items
    })

    return {
      products,
      ctaLink,
      isExternal,
      hasLink
    }
  }
})
